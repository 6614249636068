import { startOfDay, endOfDay } from 'date-fns'
import { OFFSET_90_DAYS, OFFSET_30_DAYS, OFFSET_365_DAYS, OFFSET_DAY } from '../constants'

import { getProductName } from '@dg-util'
import { PartnerName } from '../types/interface/quote.interface'
import { PolicyV2Type, QuoteV2Type } from '../types/ApiV2'

interface ProductMap {
  [key: string]: number
}
interface DateCalculationMap {
  [key: string]: ProductMap
}

interface ProductCalculation {
  [key: string]: () => ProductDateMap
}
interface PartnerProductDateCalculations {
  [key: string]: ProductCalculation
}

interface ProductDateMap {
  min: Date
  max: Date
  selected: Date
}

export const convertIntoDateWithOffset = (offset: number): Date =>
  endOfDay(new Date(Date.now() + offset))

const defineOffsetNumber = (realCompany = 0): number => {
  return (
    {
      [PartnerName.instabank]: {
        travel: realCompany ? OFFSET_30_DAYS : 0,
        content: realCompany ? OFFSET_30_DAYS : 0,
        vehicle: 0,
      },
      [PartnerName.nord]: {
        travel: realCompany ? OFFSET_30_DAYS : 0,
        content: realCompany ? OFFSET_30_DAYS : 0,
        vehicle: 0,
      },
      [PartnerName.circlek]: {
        vehicle: 0,
      },
      [PartnerName.volkswagen]: {
        vehicle: 0,
      },
      [PartnerName.skoda]: {
        vehicle: 0,
      },
      [PartnerName.audi]: {
        vehicle: 0,
      },
    } as DateCalculationMap
  )[PARTNER_NAME][getProductName()]
}

const getStartDate = (realCompany?: number): Date =>
  convertIntoDateWithOffset(defineOffsetNumber(realCompany))

const getDateVehicleNo = (quote: Partial<QuoteV2Type>) => (): ProductDateMap => {
  const startDate = getStartDate()

  const quoteOwner = quote.canVerify
  const policyStartDate = new Date(quote.EarliestStartDateOfInsurance)
  const selectedDate = policyStartDate.getTime() < startDate.getTime() ? startDate : policyStartDate

  return {
    min: startOfDay(quoteOwner ? selectedDate : startDate),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS),
    selected: quoteOwner ? selectedDate : startDate,
  }
}

const getDateTravelNo = (realCompany?: number) => (): ProductDateMap => {
  const startDate = getStartDate(realCompany)

  return {
    min: startOfDay(startDate),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS),
    selected: startDate,
  }
}

const getDateContentNo = (realCompany?: number) => (): ProductDateMap => {
  const startDate = getStartDate(realCompany)

  return {
    min: startOfDay(startDate),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS),
    selected: startDate,
  }
}

const getDateVehicleSe = (): ProductDateMap => {
  const startDate = getStartDate()

  return {
    min: startOfDay(startDate),
    max: convertIntoDateWithOffset(OFFSET_365_DAYS),
    selected: null,
  }
}

const getStartNewDate = (offset: number) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  currentDate.setDate(currentDate.getDate() + offset)

  return currentDate
}

const getFutureDate = (date: Date, offset: number) => new Date(date.getTime() + offset)

export const definePolicyChangeStartDate = (policy: Partial<PolicyV2Type>) => {
  return {
    min: startOfDay(new Date(policy.InsuredFrom)),
    max: getFutureDate(new Date(policy.InsuredFrom), OFFSET_90_DAYS),
    selected: new Date(policy.InsuredFrom),
  }
}

export const definePolicyNotChangeDate = (offset: number) => {
  return {
    min: getStartNewDate(offset),
    max: convertIntoDateWithOffset(OFFSET_90_DAYS + OFFSET_DAY),
    selected: getStartNewDate(offset),
  }
}

const defineOfferDate = (quote: Partial<QuoteV2Type>, realCompany?: number): ProductDateMap =>
  (
    ({
      [PartnerName.circlek]: {
        vehicle: getDateVehicleSe,
      },
      [PartnerName.instabank]: {
        vehicle: getDateVehicleNo(quote),
        travel: getDateTravelNo(realCompany),
        content: getDateContentNo(realCompany),
      },
      [PartnerName.nord]: {
        vehicle: getDateVehicleNo(quote),
        travel: getDateTravelNo(realCompany),
        content: getDateContentNo(realCompany),
      },
      [PartnerName.volkswagen]: {
        vehicle: getDateVehicleNo(quote),
      },
      [PartnerName.skoda]: {
        vehicle: getDateVehicleNo(quote),
      },
      [PartnerName.audi]: {
        vehicle: getDateVehicleNo(quote),
      },
    }) as PartnerProductDateCalculations
  )[PARTNER_NAME][getProductName()]()

export default defineOfferDate
