import Joi from 'joi'
import format from 'date-fns/format'
import { FORMAT_DAY_MONTH_YEAR_DOT } from '../constants'

export const nameValidation = (fieldName: string, translationKey: string, min?: number) =>
  Joi.string()
    .required()
    .trim()
    .empty()
    .min(min || 1)
    .max(100)
    .messages({
      'string.base': translationKey,
      'string.empty': translationKey,
      'string.min': translationKey,
      'string.max': translationKey,
      'any.required': translationKey,
    })

export const legalAidDateValidation = (min: Date, max: Date) => {
  const formattedMin = format(min, FORMAT_DAY_MONTH_YEAR_DOT)
  const formattedMax = max && format(max, FORMAT_DAY_MONTH_YEAR_DOT)

  return Joi.date()
    .required()
    .empty()
    .min(min)
    .max(max)
    .messages({
      'date.base': 'accident.form.occurDate.validation.base',
      'date.empty': 'accident.form.occurDate.validation.base',
      'date.max': `accident.form.occurDate.validation.pattern?min=${formattedMin}&max=${formattedMax}`,
      'date.min': `accident.form.occurDate.validation.pattern?min=${formattedMin}&max=${formattedMax}`,
      'any.required': ``,
    })
}
