import React, { lazy } from 'react'
import { Typography } from '@dg-shared'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { getProductName } from '@dg-util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
      margin: theme.spacing(3),
    },
  })
)

const LazyComp = lazy(() => import(`./testComponent.${getProductName()}.tsx`))

const TestComponent = () => {
  const { wrapper } = useStyles()
  return (
    <>
      <div className={wrapper}>
        <Typography type='h1' color='main'>
          Default Test Component
        </Typography>
        <Typography>Default content and resolved by webpack modules resolver</Typography>
        <Typography color='textError'>Path to this component is:</Typography>
        <Typography color='main' size='xSmall'>
          src/brand/default/components/testComponent.tsx
        </Typography>
      </div>
      <LazyComp />
    </>
  )
}

export default TestComponent
