import { ComponentType } from 'react'
import i18next from 'i18next'
import { ObjectSchema } from 'joi'
import { get, isArray, isObject, mergeWith } from 'lodash'

import { SessionData } from '../types/interface/session.interface'
import { REGEX_NUMBER_EMPTY_SPACE, REGEX_THOUSANDS_SEPARATOR } from '../constants/regex-constants'
import { AddressComponent } from '../types/interface/map.interface'
import { LocalizedListItemType } from '@dg-shared/CoverageInfoList'

export type FormValidator = <FormFields>(values: FormFields) => Record<string, unknown>
export type SchemaValidator = (schema: ObjectSchema) => FormValidator

const customizer = (objValue: unknown, srcValue: unknown) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

interface DeepMergeFunction {
  <Key1, Key2>(target: Key1, source: Key2): Key1 & Key2
}

export const mergeDeep: DeepMergeFunction = (target, source) => {
  return mergeWith(target, source, customizer)
}

export const mergeDeepArray = <T extends object>(items: T[]): T => {
  let merged = {} as T

  items.forEach((item) => {
    merged = mergeWith(merged, item, customizer)
  })

  return merged
}

// Filter items which conditions don't match form conditions(value) out of a list.
export const filterTranslationByCondition = (
  list: Array<LocalizedListItemType>,
  translationCondition?: string
) => {
  return list?.filter((el: LocalizedListItemType) => {
    if (isObject(el) && el.condition && translationCondition) {
      return translationCondition === el.condition
    }
    return true
  })
}

//TODO: Think on better way of implementing this via JSON/regexp
export const translateValidationError = (error: string) => {
  const [translationKey, valueArr] = error.split('?')
  const valueObj: Record<string, string> = {}

  if (valueArr) {
    valueArr.split('&').map((el) => {
      const [key, value] = el.split('=')
      return (valueObj[key] = value)
    })
  }

  // eslint-disable-next-line import/no-named-as-default-member
  return i18next.t(translationKey, { ...valueObj, defaultValue: '' })
}

export const formValidator: SchemaValidator = (schema: ObjectSchema) => (values) => {
  const result = schema.validate(values, { abortEarly: false })
  let errors = {}

  if (result.error) {
    errors = result.error.details.reduce(
      (acc, error) => ({
        ...acc,
        [error.path.join('.')]: translateValidationError(error.message),
      }),
      {}
    )
  }

  return errors
}

export const getSessionStorageAuthData = (storageKey: string): SessionData => {
  const userInfo = sessionStorage.getItem(storageKey)
  return !!userInfo && JSON.parse(userInfo)
}

export const getDisplayName = <P>(WrappedComponent: ComponentType<P>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor
  return /iPad|iPhone|iPod/.test(userAgent)
}

export const formatMapLocations = (locations: Array<AddressComponent>) => {
  const filterLocation = (locationType: string, locations: Array<AddressComponent>) =>
    locations.filter((el: AddressComponent) => el.types.includes(locationType))[0]

  const route = filterLocation('route', locations)
  const routeName = get(route, 'long_name', '')
  const buildingNumber = filterLocation('street_number', locations)
  const buildingNumberName = get(buildingNumber, 'long_name', '')
  const localityCity = filterLocation('locality', locations)
  const localityTown = filterLocation('postal_town', locations)
  const locality = localityCity || localityTown
  const country = filterLocation('country', locations)
  const address = routeName && buildingNumberName ? [routeName, buildingNumberName].join(' ') : ''
  const postcode = filterLocation('postal_code', locations)

  return {
    address1: address,
    city: get(locality, 'long_name', ''),
    country: get(country, 'long_name', ''),
    postNumber: get(postcode, 'long_name', ''),
  }
}

export const formatWithThousandSpace = (val: string | number, reverseTrim?: boolean): string => {
  if (!val) {
    return ''
  }

  let formattedValue
  if (reverseTrim) {
    formattedValue = val.toString().replace(REGEX_NUMBER_EMPTY_SPACE, '')
    return formattedValue.replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  }

  if (typeof val === 'number') {
    formattedValue = val.toString().replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  } else if (typeof val === 'string') {
    formattedValue = val.replace(REGEX_THOUSANDS_SEPARATOR, '$1 ')
  } else return ''

  return formattedValue
}

export const toUpperCase = (value: string): string => value && value.toUpperCase()

export const removeAllSpaces = (val: string): string => val && val.replace(/\s/g, '')

export const isAddressValid = (locations: Array<AddressComponent> = []) => {
  if (locations.length < 1) {
    return false
  }

  const { address1, postNumber, city } = formatMapLocations(locations)

  return address1 && postNumber && city
}

export const getPolicyNumberWithVersionUriPath = (policyNumber: number, policyVersion?: number) => {
  return [policyNumber, policyVersion].filter((i) => i).join('/')
}
