import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import BrandConfig from 'BrandConfig'
import { ErrorCode, ERROR_TYPE } from '../../constants'
import { TRANSLATIONS as T } from '../../constants/translations-constants'
import { FSMBtnNext, LDCardTwoCol, LMBgHeader, Typography, withHidden } from '@dg-shared'
import { useTranslate } from '../../hooks/shared'
import { Events } from '../../FSM/shared/constants'
import { logoMax, logoMin } from 'BrandedImages'
import { PartnerName } from '../../types/interface/quote.interface'
import { CommonPageProps } from '../../types/interface/fsm.interface'
import { isCriticalError } from '@dg-util'

const MobileScreen = withHidden(LMBgHeader, true)
const DesktopScreen = withHidden(LDCardTwoCol, false)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > a': {
        textDecoration: 'none',
      },
    },
    buttonNext: {
      color: theme.palette.secondary.contrastText,
      marginBottom: theme.spacing(2),
      ...theme.brand.assets.layout?.ctaOverride,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    ctaText: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
      color: theme.brand.colors.textSecondary,
      ...theme.brand.assets.layout?.PolicyCTAOverride,
    },
    ctaLink: {
      fontWeight: 700,
      ...theme.brand.assets.layout?.ErrorPageCtaLink,
    },
    link: (externalLink) => ({
      color: theme.brand.colors.secondary,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: externalLink ? 'underline' : 'none',
      },
    }),
    backBtn: {
      alignSelf: 'start',
      marginBottom: theme.spacing(4),
    },
    indentBigger: {
      marginBottom: theme.spacing(2),
    },
    header: {
      width: '250px',
      ...theme.brand.assets.layout?.errorHeader,
    },
  })
)

interface ErrorPageProps extends CommonPageProps {
  type: string
  returnPath?: string
  isCurrentHistoryReload?: boolean
}

const ErrorPage: FC<ErrorPageProps> = ({ currentState, ...props }) => {
  const { t } = useTranslate(T.ERROR)
  const errorType =
    props.service.state.event.errorType || ERROR_TYPE[ErrorCode.ERROR_CODE_QUOTE_GENERAL]

  const classes = useStyles({ externalLink: true })
  const { send } = props.service

  const handleClick = () => {
    send({
      ...props.service.state.event,
      type: Events.BACK,
      state: currentState,
    })
  }

  const nextButtonText =
    errorType.code === ErrorCode.ERROR_CODE_BANKID_OUT
      ? t('bankid.nextButton')
      : isCriticalError(errorType.code)
        ? t('finished')
        : t('backBtn')

  const nextBtn = (
    <FSMBtnNext
      customClassName={classes.buttonNext}
      handleClick={handleClick}
      buttonText={nextButtonText}
    />
  )

  const footer = (
    <footer className={classes.footer}>
      {/*FIXME: Currently used only on FNOL. Work on it later*/}
      {/*{errorType.showBtn ? (!!isCurrentHistoryReload ? reloadBtn : nextBtn) : null}*/}
      {errorType.showBtn && nextBtn}

      {(errorType.code === ErrorCode.ERROR_CODE_QUOTE_GENERAL ||
        [
          PartnerName.instabank,
          PartnerName.volkswagen,
          PartnerName.skoda,
          PartnerName.audi,
          PartnerName.nord,
        ].includes(PARTNER_NAME as PartnerName)) && (
        <>
          <Typography className={classes.ctaText}>{t(`CTA.question`)}</Typography>
          {BrandConfig.SUPPORT_LINK ? (
            <a href={BrandConfig.SUPPORT_LINK} target='_self' className={classes.link}>
              <Typography color='main' modifiers={['bold']}>
                {t(errorType.linkText)}
              </Typography>
            </a>
          ) : (
            <a
              href={`mailto:${BrandConfig.EMAIL}`}
              target='_blank'
              rel={'noreferrer'}
              className={classes.link}
            >
              <Typography
                color={'buttonActive'}
                className={`${classes.ctaText} ${classes.ctaLink}`}
              >
                {t('CTA.contactEmail', { email: BrandConfig.EMAIL })}
              </Typography>
            </a>
          )}
        </>
      )}
    </footer>
  )

  const content = (
    <section className={classes.content} id='error-content'>
      {t(`${errorType.content}`, { email: BrandConfig.EMAIL }, 'obj')?.map((el, idx) => (
        <Typography
          key={`${errorType.content}-${idx}`}
          className={classes.indentBigger}
          html={true}
        >
          {el}
        </Typography>
      ))}
      {errorType.emailText && (
        <a
          href={`mailto:${BrandConfig.EMAIL}`}
          target='_blank'
          rel={'noreferrer'}
          className={classes.link}
        >
          <Typography color='textMain' html={true}>
            {t(errorType.emailText, { email: BrandConfig.EMAIL })}
          </Typography>
        </a>
      )}
    </section>
  )

  const testProps = {
    'data-errorCode': errorType.code,
  }

  return (
    <>
      <MobileScreen
        headerTitle={t(errorType.title)}
        textLogoAlt={t(errorType.title)}
        headerClass={classes.header}
        brandPageBackground={errorType.errorCardMobile}
        imageLogo={logoMin}
        imageGradient
        testProps={testProps}
      >
        {content}
        {footer}
      </MobileScreen>
      <DesktopScreen
        imageLogo={logoMax}
        brandPageBackground='error'
        brandPageBackgroundCard='errorCardMain'
        textLogoAlt={t('title')}
        isReverse
        testProps={testProps}
      >
        <Typography type='h1'>{t(errorType.title)}</Typography>
        {content}
        {footer}
      </DesktopScreen>
    </>
  )
}

export default ErrorPage
