import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import ErrorBoundary from '@dg-shared/ErrorBoundary'
import { IS_PRODUCTION } from './config'

const noop = () => {
  /* no operation */
}

// Hide all logs on production
// replace console.log, error, warn and info with no operational functions
// This might be replaced with a webPack implementation (like TerserPlugin with optimization drop_comments: true)
// - tried the TerserPlugin on DEV but it unsuccessfully - need more investigation
if (IS_PRODUCTION) {
  window.console = {
    ...window.console,
    log: () => noop,
    error: () => noop,
    info: () => noop,
    warn: () => noop,
  }
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
